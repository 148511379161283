import { tCodeStyleSettingSchema, tInspectionProfileSettingSchema, tKeyValueListSettingSchema, tKindAwareSchema, tPersistentStateXmlSettingSchema, tPersistentStateXpathSettingSchema, tPropertiesComponentSettingSchema, tRegistrySettingSchema } from '@app/pages/profiles/profile-details/profile-ide-provisioner/schemas/ide-setting-schema';
export const getEmptyFilters = () => {
    return [
        {
            products: [],
            builds: [],
            type: 'include-all',
            arch: [],
            os: []
        }
    ];
};
export const getEmptyFilter = () => {
    return {
        products: [],
        builds: [],
        type: 'include-all',
        arch: [],
        os: []
    };
};
export const getToolboxIncludeFilter = () => {
    return {
        products: ['Toolbox'],
        type: 'include'
    };
};
export const getToolboxTool = () => {
    return {
        product: 'Toolbox',
        productName: 'JetBrains Toolbox'
    };
};
export const getEmptyVmOption = () => {
    return {
        id: '',
        filters: getEmptyFilters(),
        tools: [],
        content: [
            {
                value: '',
                forced: false
            }
        ]
    };
};
export const getDefaultToolboxSettings = () => {
    return {
        id: '',
        filters: [getToolboxIncludeFilter()],
        tools: [getToolboxTool()],
        content: {}
    };
};
export const getEmptyAutoinstalledTool = () => {
    return {
        id: '',
        filters: getEmptyFilters(),
        tools: []
    };
};
export function getIdeSettingsFormValues() {
    return {
        id: '',
        filters: getEmptyFilters(),
        tools: [],
        content: []
    };
}
export function getSchemaByKind(kind) {
    if (kind === 'PERSISTENT_STATE_XML') {
        return tPersistentStateXmlSettingSchema;
    }
    if (kind === 'PERSISTENT_STATE_XPATH') {
        return tPersistentStateXpathSettingSchema;
    }
    if (kind === 'REGISTRY') {
        return tRegistrySettingSchema;
    }
    if (kind === 'PROPERTIES_COMPONENT') {
        return tPropertiesComponentSettingSchema;
    }
    if (kind === 'CODESTYLE_XML') {
        return tCodeStyleSettingSchema;
    }
    if (kind === 'INSPECTION_PROFILE_XML') {
        return tInspectionProfileSettingSchema;
    }
    if (kind === 'KEY_VALUE_LIST') {
        return tKeyValueListSettingSchema;
    }
    return tKindAwareSchema;
}
export function getXpathSpecByAction(action) {
    if (action === 'replace-child') {
        return {
            action,
            xpath: '',
            'child-xml': '',
            'keep-attributes': false,
            'keep-name': false,
            'keep-content': false,
            'child-rel-xpath': '',
            'insert-before-rel-xpath': '',
            'insert-after-rel-xpath': ''
        };
    }
    if (action === 'replace-text') {
        return {
            action,
            xpath: '',
            text: '',
            cdata: false
        };
    }
    if (action === 'set-attribute') {
        return {
            action,
            xpath: '',
            name: '',
            value: ''
        };
    }
    // action === 'remove'
    return { action, xpath: '' };
}
export const getEmptyDisabledPluginsFormValues = () => {
    return {
        id: '',
        filters: getEmptyFilters(),
        tools: [],
        content: [
            {
                pluginId: '',
                description: '',
                forced: false
            }
        ]
    };
};
export const getAutoInstalledFormContentPlugin = (pluginId = '', source = 'TBE') => {
    return {
        type: 'channel',
        pluginId,
        description: 'According to corporate rules new plugins were added to the IDE',
        forced: false,
        source,
        version: '',
        channel: undefined
    };
};
export const getEmptyAutoInstalledPluginFormValues = () => {
    return {
        id: '',
        filters: getEmptyFilters(),
        tools: [],
        content: []
    };
};
export const getEmptyDisabledPluginField = () => {
    return {
        pluginId: '',
        description: '',
        forced: false
    };
};
export const getEmptyApplicationForm = () => {
    return {
        product: '',
        productName: '',
        includedNodes: [''],
        excludedNodes: [''],
        notifications: [],
        include: [
            {
                build: '',
                version: ''
            }
        ],
        exclude: [
            {
                build: '',
                version: ''
            }
        ],
        // TEMP SOLUTION - will be added in another iteration
        // os: ['windows', 'linux', 'macOs'],
        // arch: ['x64', 'arm64'],
        excluded: true
    };
};
export const getEmptyNotificationForm = () => {
    return {
        id: '',
        filters: [],
        notification: {
            id: '',
            description: 'Please update to the latest available version',
            dueDate: ''
        }
    };
};
