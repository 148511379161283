import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Radio from '@jetbrains/ring-ui/components/radio/radio';
import Icon from '@jetbrains/ring-ui/components/icon';
import trashIcon from '@jetbrains/icons/trash';
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import classNames from 'classnames';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import styles from './provider-form-base.css';
export function ProviderFormBase({ label, children, isDefaultProvider, onDefaultProviderChange, onDelete, configured }) {
    return (_jsxs("div", { className: classNames(styles.container, {
            [styles.configurationRequired]: !configured,
            [styles.isDefaultProvider]: configured && isDefaultProvider
        }), children: [_jsxs("div", { className: styles.headerContainer, children: [_jsxs("div", { className: styles.titleContainer, children: [_jsx(H3, { children: label }), !configured && _jsx(Badge, { className: styles.badge, children: "Configuration required" })] }), _jsxs("div", { className: styles.actionsContainer, children: [_jsx(Tooltip, { title: "Only one default provider can be selected", children: _jsx(Radio.Item, { checked: isDefaultProvider, value: "", onChange: onDefaultProviderChange, children: "Default" }) }), _jsx(Icon, { glyph: trashIcon, onClick: onDelete, className: styles.deleteIcon })] })] }), children] }));
}
