import * as z from 'zod';
export function zodBackendValidationError(ctx) {
    return (apiError) => {
        var _a, _b;
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: (_b = (_a = apiError.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.detail
        });
    };
}
