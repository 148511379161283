import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { format } from 'date-fns';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { formatApiError } from '@api/errors';
import { downloadReport, sendUsage } from '@api/tbe-license';
import HorizontalContainer from '@app/common/page-layout/horizontal-container';
import auth from '@app/common/auth/auth';
import { ExternalLinks } from '@app/common/constants/constants';
import NavigationLink from '@app/common/navigation/navigation-link';
import { TitledBlock } from '@common/titled-block/titled-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { DocumentationLink } from '@common/documentation/documentation-link/documentaion-link';
import styles from './usage-stats.css';
export function UsageStats({ className }) {
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const sendUsageStatistics = useCallback(async () => {
        await sendUsage();
        alert.successMessage('Report sent to JetBrains');
    }, []);
    const downloadUsageStatistics = useCallback(async () => {
        const reportValue = await getReportValue();
        if (reportValue) {
            saveStringAsFile(reportValue, `usage-${format(new Date(), 'yyyy-MM-dd')}.txt`);
        }
        async function getReportValue() {
            try {
                return (await downloadReport()).value;
            }
            catch (err) {
                alert.error(formatApiError(err, 'Failed to download report data'));
                return null;
            }
        }
    }, []);
    return (_jsxs(TitledBlock, { title: "Usage Report", ...assignTestId('usage-report-title'), className: className, children: [auth.getFeatures().offlineModeEnabled ? (_jsxs(Text, { children: [_jsxs(Text, { children: ["Your JetBrains IDE Services server is in offline mode. Download the JetBrains IDE Services usage report and submit it using your", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_ACCOUNT_USAGE_STATS, children: "JetBrains Account" }), "."] }), _jsx("br", {})] })) : (_jsxs(Text, { children: ["JetBrains IDE Services automatically submits usage reports to your JetBrains Account.", _jsx("br", {}), "These reports include your organization's consumption of products and are necessary for billing purposes.", ' '] })), _jsxs(Text, { children: [_jsx(DocumentationLink, { topic: "USAGE_REPORT", children: "Learn more" }), "."] }), _jsxs(HorizontalContainer, { className: styles.buttonsContainer, children: [_jsx(Button, { onClick: downloadUsageStatistics, download: true, children: "Download usage report" }), !offlineModeEnabled && _jsx(Button, { onClick: sendUsageStatistics, children: "Send usage report" })] })] }));
}
function saveStringAsFile(content, fileName) {
    const blob = new window.Blob([content], { type: 'text/plain' });
    const urlWindowObject = window.URL || window.webkitURL || {};
    if (!urlWindowObject.createObjectURL || !urlWindowObject.revokeObjectURL) {
        showBrowserError();
        return;
    }
    const url = urlWindowObject.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    urlWindowObject.revokeObjectURL(url);
    document.body.removeChild(link);
    function showBrowserError() {
        alert.error(`Failed to download report data: browser error. Please make sure you are not using an older version of the browser. We support
        two latest Chrome, Safari, Edge and Firefox versions.`);
    }
}
