// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxHeading__ba9 {
  display: flex;
  gap: calc(var(--ring-unit) / 4);
  font-weight: 600;
  flex-wrap: wrap;
  align-items: center;
}

.productLogos__c99 {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.productLogo__d83 {
  height: var(--ring-line-height);
}

.productLogoTooltip__c82 {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/common/tools-preview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".boxHeading {\n  display: flex;\n  gap: calc(var(--ring-unit) / 4);\n  font-weight: 600;\n  flex-wrap: wrap;\n  align-items: center;\n}\n\n.productLogos {\n  display: flex;\n  gap: 6px;\n  flex-wrap: wrap;\n}\n\n.productLogo {\n  height: var(--ring-line-height);\n}\n\n.productLogoTooltip {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxHeading": `boxHeading__ba9`,
	"productLogos": `productLogos__c99`,
	"productLogo": `productLogo__d83`,
	"productLogoTooltip": `productLogoTooltip__c82`
};
export default ___CSS_LOADER_EXPORT___;
