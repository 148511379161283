import * as z from 'zod';
import { memoize } from 'lodash';
import { validateVmOption } from '@api/ide-vm-options';
import { zodBackendValidationError } from '@common/errors/zod-backend-validation-error';
import { tSettingOptionSchema } from './setting-option-schema';
const memoizedValidateVMOptions = memoize(validateVmOption);
export const tVmOptionsSchema = tSettingOptionSchema.extend({
    content: z
        .array(z.object({
        value: z
            .string()
            .min(1, { message: 'Please enter VM Option' })
            .superRefine((vmOption, ctx) => vmOption && memoizedValidateVMOptions(vmOption).catch(zodBackendValidationError(ctx)))
            .transform(it => it || ''),
        forced: z.boolean()
    }))
        .min(1, { message: 'Please add VM Option' })
});
