import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { ListProps } from '@jetbrains/ring-ui/components/popup-menu/popup-menu';
import DropdownMenu from '@jetbrains/ring-ui/components/dropdown-menu/dropdown-menu';
import Text from '@jetbrains/ring-ui/components/text/text';
import Avatar, { Size as AvatarSize } from '@jetbrains/ring-ui/components/avatar/avatar';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { AppGlobal, ExternalLinks } from '@app/common/constants/constants';
import NavigationLink from '@app/common/navigation/navigation-link';
import { useServerConfigContext } from '@app/common/server-config-context/server-config-context';
import auth from '@app/common/auth/auth';
import { licenseActivationService } from '@common/licensing/license-activation-service';
import { getDocumentationLink } from '@common/documentation/documentation-link/documentaion-link';
import { useColorMode } from '../../color-mode-context';
import styles from './app-header.css';
const logout = () => {
    auth.logout().then(() => window.location.assign('/'));
};
export default function AuthorizedHeaderDropdown() {
    var _a;
    const { toggleColorMode, colorMode } = useColorMode();
    const { configData, notifications, setNotifications } = useServerConfigContext();
    const username = ((_a = auth.getUserProfile()) === null || _a === void 0 ? void 0 : _a.username) || 'Guest';
    const hasAnalyticsEnabled = auth.getFeatures().analyticsEnabled;
    const menuProps = useMemo(() => ({
        top: 8,
        closeOnSelect: true,
        'data-test': 'header-current-user-dropdown'
    }), []);
    const showAvatarNotification = notifications !== null && (notifications.upgradeVersion || notifications.whatsNew);
    const newVersionOptions = useMemo(() => {
        if (isNil(configData)) {
            return [];
        }
        return [
            {
                key: 'whats-new',
                rgItemType: ListProps.Type.ITEM,
                onClick: () => window.open(configData.releaseNotesUrl),
                onMouseUp: () => setNotifications('whatsNew'),
                rightNodes: (notifications === null || notifications === void 0 ? void 0 : notifications.whatsNew) && (
                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                _jsx("div", { className: styles.sectionNotification, onMouseOver: () => setNotifications('whatsNew') })),
                label: `Read about what's new`
            },
            {
                key: 'new-version-separator',
                rgItemType: ListProps.Type.SEPARATOR
            }
        ];
    }, [configData, notifications, setNotifications]);
    const myProfileOption = useMemo(() => {
        if (auth.hasRoles(['ADMIN']) && licenseActivationService.hasLicense()) {
            return [
                {
                    key: 'my-profile',
                    rgItemType: ListProps.Type.CUSTOM,
                    template: () => (_jsx(NavigationLink, { className: classNames(styles.navLinkContainer, styles.clearLinkStyles), href: "/users/me", children: "My user page" }))
                }
            ];
        }
        return [];
    }, []);
    const baseOptions = useMemo(() => {
        return [
            ...myProfileOption,
            {
                key: 'toggle-color-mode',
                rgItemType: ListProps.Type.CUSTOM,
                onClick: toggleColorMode,
                template: () => (_jsxs(Text, { onClick: toggleColorMode, "data-test": "ui-color-scheme-toggle", children: [colorMode === 'dark' ? 'Light' : 'Dark', " mode"] }))
            },
            {
                key: 'color-mode-separator',
                rgItemType: ListProps.Type.SEPARATOR
            },
            {
                key: 'read-help',
                rgItemType: ListProps.Type.ITEM,
                onClick: () => window.open(getDocumentationLink('GET_STARTED')),
                label: 'Documentation'
            },
            {
                key: 'third-party-licenses',
                rgItemType: ListProps.Type.CUSTOM,
                template: () => (_jsx(NavigationLink, { className: classNames(styles.navLinkContainer, styles.clearLinkStyles), href: "/third-party-licenses", "data-test": "third-party-licenses", children: "Third-party licenses" }))
            },
            hasAnalyticsEnabled
                ? {
                    key: 'transparency-ide-details',
                    rgItemType: ListProps.Type.CUSTOM,
                    template: () => (_jsx(NavigationLink, { className: classNames(styles.navLinkContainer, styles.clearLinkStyles), href: "/transparency/ide_details", "data-test": "transparency/ide-details", children: "Data Collection Statement" }))
                }
                : {
                    key: 'nothing',
                    rgItemType: ListProps.Type.MARGIN
                },
            {
                key: 'bug-report-separator',
                rgItemType: ListProps.Type.SEPARATOR
            },
            {
                key: 'bug-report',
                rgItemType: ListProps.Type.ITEM,
                onClick: () => window.open(ExternalLinks.BUG_REPORT),
                label: 'Report bug',
                target: '_blank'
            },
            {
                key: 'submit-feedback',
                rgItemType: ListProps.Type.ITEM,
                onClick: () => window.open(ExternalLinks.FEEDBACK),
                label: 'Share feedback',
                target: '_blank'
            },
            {
                key: 'contact-support',
                rgItemType: ListProps.Type.ITEM,
                onClick: () => window.open(ExternalLinks.SUPPORT),
                label: 'Contact support',
                target: '_blank'
            },
            {
                key: 'logout-separator',
                rgItemType: ListProps.Type.SEPARATOR
            },
            {
                key: 'logout',
                rgItemType: ListProps.Type.CUSTOM,
                onClick: () => logout(),
                template: _jsx(Text, { "data-test": "user-logout-button", children: "Log out" })
            },
            {
                key: 'copyright-separator',
                rgItemType: ListProps.Type.SEPARATOR
            },
            {
                key: 'copyright',
                rgItemType: ListProps.Type.CUSTOM,
                disabled: true,
                template: (_jsxs(Text, { info: true, children: [`JetBrains IDE Services ${AppGlobal.BUILD_NUMBER}`, " ", _jsx("br", {}), " ", AppGlobal.COPYRIGHT] }))
            }
        ];
    }, [colorMode, hasAnalyticsEnabled, myProfileOption, toggleColorMode]);
    const dropdownAnchor = useCallback((_, ariaProps) => (_jsxs("div", { className: styles.avatarContainer, children: [_jsx(Text, { info: true, className: styles.userNameInfo, "data-test": "current-user-name", children: username }), _jsx(Avatar, { className: classNames(styles.avatar, {
                    [styles.avatarNotification]: showAvatarNotification
                }), username: username, size: AvatarSize.Size32, title: username, ...ariaProps })] })), [showAvatarNotification, username]);
    return (_jsx(DropdownMenu, { "data-test": "header-current-user-menu", anchor: dropdownAnchor, menuProps: menuProps, data: [...newVersionOptions, ...baseOptions] }));
}
