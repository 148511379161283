import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import NavigationLink from '@common/navigation/navigation-link';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import auth from '@common/auth/auth';
const documentationBaseUrl = 'https://www.jetbrains.com/help';
const documentationRelativeUrls = {
    GET_STARTED: 'get-started.html',
    USAGE_REPORT: 'license-plans.html#usage_reports',
    PROFILE_CHANGES: 'configure-profiles.html#test_profiles',
    OFFLINE_MODE: 'offline-mode.html',
    OFFLINE_MODE_CLI: 'offline-mode.html#download_artifacts_using_tbe_cli',
    DISTRIBUTE_CONFIGURATION: 'prepare-the-server-for-developer-machine-access.html#share_machine_config',
    ACCESS_MANAGEMENT: 'access-management.html'
};
export function getDocumentationLink(topic, isCloud = !!auth.getFeatures().cloudModeEnabled) {
    const pathToTopic = documentationRelativeUrls[topic];
    return `${documentationBaseUrl}/ide-services${isCloud ? '-cloud' : ''}/${pathToTopic}`;
}
export function DocumentationLink({ topic, children, ...restProps }) {
    const link = useMemo(() => getDocumentationLink(topic), [topic]);
    return (_jsx(NavigationLink, { href: link, target: "_blank", ...restProps, ...assignTestId('documentation-link', restProps), children: children }));
}
