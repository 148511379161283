import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import Input, { Size } from '@jetbrains/ring-ui/components/input/input';
import Text from '@jetbrains/ring-ui/components/text/text';
import { decodeQueryParams, searchStringToObject, StringParam } from 'serialize-query-params';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { useTbeLicenseActivationLink } from '@app/common/queries/tbe-license';
import CopiedText from '@app/common/clipboard/copied-text/copied-text';
import { Collapsible } from '@app/common/collapsible/collapsible';
import CopiedCode from '@app/common/clipboard/copied-code/copied-code';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import auth from '@common/auth/auth';
import NavigationLink from '@common/navigation/navigation-link';
import { ExternalLinks } from '@common/constants/constants';
import styles from './license-activation-dialog.css';
export function LicenseActivationContent({ inputValue, inputValueOnChange }) {
    const cloudModeEnabled = !!auth.getFeatures().cloudModeEnabled;
    const { data: activationLink, isLoading } = useTbeLicenseActivationLink();
    const publicKey = useMemo(() => {
        if (!activationLink) {
            return null;
        }
        const search = searchStringToObject(activationLink.split('?').pop() || '');
        return decodeQueryParams({ instPubKey: StringParam }, search).instPubKey || '';
    }, [activationLink]);
    const getActivationUrlClick = useCallback(() => {
        return activationLink !== null && activationLink !== void 0 ? activationLink : '';
    }, [activationLink]);
    if (isLoading) {
        return _jsx(Loader, {});
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [cloudModeEnabled ? (_jsxs(Text, { children: ["JetBrains IDE Services should activate automatically. If it doesn't happen, try refreshing the page. If the issue persists, please contact", ' ', _jsx(NavigationLink, { target: "_blank", href: ExternalLinks.SUPPORT, children: "JetBrains IDE Services support" }), ' ', "to request an activation code and manually enter it in the field below."] })) : (_jsx(ActivationInstruction, { activationLink: activationLink, getActivationUrlClick: getActivationUrlClick })), !!activationLink && !cloudModeEnabled && (_jsx(Collapsible, { title: "Link to JetBrains Account", primary: true, children: _jsx(CopiedCode, { text: activationLink, softWrap: true }) })), !!publicKey && (_jsx(Collapsible, { title: "License Public Key", primary: true, children: _jsx(CopiedCode, { text: publicKey, softWrap: true }) }))] }), _jsx(Input, { label: "Activation code from JetBrains Account", placeholder: "Paste activation code", rows: 6, size: Size.AUTO, value: inputValue, multiline: true, inputClassName: styles.licenseTextarea, onChange: e => inputValueOnChange(e.target.value), "data-gramm": "false", "data-gramm_editor": "false", "data-enable-grammarly": "false", ...assignTestId('activation-license-input') })] }));
}
function ActivationInstruction(props) {
    const { getActivationUrlClick, activationLink } = props;
    return (_jsxs(Text, { children: ["To activate the JetBrains IDE Services license for your organization:", _jsxs("ol", { children: [_jsxs("li", { children: [!activationLink ? (_jsx(Text, { children: "Copy the activation link" })) : (_jsx(CopiedText, { includeIcon: false, text: "Copy the activation link", getValue: getActivationUrlClick, className: styles.copyLink })), ' ', "and open it on a machine connected to the Internet."] }), _jsx("li", { children: "On your JetBrains Account, activate JetBrains IDE Services. You will obtain an activation code." }), _jsx("li", { children: "Paste the activation code into the text area below." })] })] }));
}
