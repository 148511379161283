import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import { noop } from 'lodash';
import cn from 'classnames';
import ButtonSet from '@jetbrains/ring-ui/components/button-set/button-set';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import confirmResetForm from '@modules/ai/common/dialogs/confirm-reset-form/confirm-reset-form';
import { useAiChangeSettingsMutation } from '@common/queries/ai-enterprise';
import { RouteLeavingGuard } from '@common/route-leaving-guard/route-leaving-guard';
import styles from '../styles.css';
export function AiFormActions({ form }) {
    const { reset, formState, getValues, trigger } = form;
    const { mutateAsync: handleActivate, isLoading } = useAiChangeSettingsMutation(() => { });
    const handleCancel = () => {
        confirmResetForm()
            .then(() => reset())
            .catch(hideConfirm);
    };
    const handleSave = () => {
        trigger().then(isValid => {
            if (isValid) {
                handleSubmit();
            }
        });
    };
    const handleSubmit = () => {
        const formValues = getValues();
        handleActivate(formValues)
            .then(data => reset(data.status === 'enabled' ? data.data : undefined))
            .catch(noop);
    };
    return (_jsxs("div", { className: cn(styles.container), children: [_jsxs(ButtonSet, { children: [_jsx(Button, { primary: true, disabled: !(formState === null || formState === void 0 ? void 0 : formState.isDirty), onClick: handleSave, loader: isLoading, children: "Save" }), _jsx(Button, { onClick: handleCancel, disabled: !(formState === null || formState === void 0 ? void 0 : formState.isDirty), children: "Cancel" })] }), _jsx(RouteLeavingGuard, { when: !!(formState === null || formState === void 0 ? void 0 : formState.isDirty) })] }));
}
