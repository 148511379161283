import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import styles from './content-field.css';
export default function TypeDescription() {
    const { watch } = useFormContext();
    const watchType = watch('type');
    const watchSource = watch('source');
    if (watchType === 'channel' && watchSource === 'MARKETPLACE') {
        return (_jsx("div", { className: styles.contentFieldBlockDescription, children: "IDE will keep being updated with the newest suitable plugin from marketplace. Users can manually override this channel in IDE unless this plugin is marked as forced." }));
    }
    if (watchType === 'channel') {
        return (_jsx("div", { className: styles.contentFieldBlockDescription, children: "IDE will keep being updated with the newest suitable plugin from either Stable or additional custom channel. Users can manually override this channel in IDE unless this plugin is marked as forced." }));
    }
    return (_jsx("div", { className: styles.contentFieldBlockDescription, children: "Choosing this plugin installation option means developers will be limited to the specific version of the plugin. They will not be able to disable or remove the plugin, upgrade or downgrade its version, or subscribe to any channels for updates. Developers will have to restart their IDEs immediately after receiving this configuration on their machines." }));
}
