import { useMutation, useQuery } from '@tanstack/react-query';
import { isEqual, isNil } from 'lodash';
import { getCurrentUser } from '@api/users';
import { createProfile, deleteProfile, getAuditLogDetails, getAuditLogsByProfileId, getProfile, getProfileOptions, getProfiles, getUsersByProfileId, postProfile, putProfile } from '../../api/profiles';
import { formatApiError } from '../../api/errors';
import queryClient, { DEFAULT_STALE_TIME } from './query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from './formatters/alerts';
import { invalidateAllQueries } from './functions';
export const keys = {
    all: ['profiles'],
    list: () => ['profiles', 'list'],
    listCtx: () => ({
        filtered: ({ query, page, sortKey, sortOrder }) => ['profiles', 'list', 'paginated', { page, sortKey, sortOrder }, 'filters', query]
    }),
    details: () => ['profiles', 'detail'],
    detail: (id) => ['profiles', 'detail', id],
    backendChanges: (id, version) => ['profiles', 'backend-changes', id, version],
    detailCtx: (id, params) => ({
        users: ['profiles', 'detail', id, 'users', { params }],
        auditLogs: (params) => ['profiles', 'detail', id, 'auditLogs', { params }],
        eventDetails: (eventId) => ['profiles', 'detail', id, 'auditLogs', eventId]
    }),
    currentUserProfile: ['userProfile']
};
const profileDetailsQuery = (id) => ({
    queryKey: keys.detail(id),
    queryFn: () => getProfile(id)
});
export function useProfileOptions(enabled = true) {
    return useQuery({
        queryKey: keys.list(),
        queryFn: () => getProfileOptions(),
        staleTime: DEFAULT_STALE_TIME,
        enabled,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load profiles'))
    });
}
export function usePaginatedProfiles(filtersWithPagination) {
    return useQuery({
        queryKey: keys.listCtx().filtered(filtersWithPagination),
        queryFn: () => getProfiles(filtersWithPagination),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load profiles'))
    });
}
export function ensureProfileDetailsQueryData(id) {
    return queryClient.ensureQueryData({ ...profileDetailsQuery(id) });
}
export function useProfileDetails(id, enabled = true) {
    return useQuery({
        ...profileDetailsQuery(id),
        enabled,
        staleTime: DEFAULT_STALE_TIME,
        refetchOnWindowFocus: 'always',
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load profile'))
    });
}
export function useProfileEvaluatedApplicationsContent(id) {
    return useQuery({
        ...profileDetailsQuery(id),
        staleTime: DEFAULT_STALE_TIME,
        refetchOnWindowFocus: 'always',
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to evaluate applications content')),
        enabled: !!id,
        select: data => data.applications.content
    });
}
export function useProfileDetailsForSelect(id, enabled) {
    return useQuery({
        ...profileDetailsQuery(id),
        enabled
    });
}
function getUsersByProfileQueryOptions(id, params) {
    return {
        queryKey: keys.detailCtx(id, params).users,
        queryFn: () => getUsersByProfileId(id, params),
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load profile users'))
    };
}
export function useUsersByProfileId(id, params, enabled = true) {
    return useQuery({
        ...getUsersByProfileQueryOptions(id, params),
        enabled,
        staleTime: DEFAULT_STALE_TIME
    });
}
export function ensureUsersByProfileId(id) {
    return queryClient.ensureQueryData(getUsersByProfileQueryOptions(id));
}
export function useProfileHistoryQuery(id, params) {
    return useQuery({
        queryKey: keys.detailCtx(id).auditLogs(params),
        queryFn: () => getAuditLogsByProfileId(id, params),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load profile history'))
    });
}
export function useProfileHistoryDetailsQuery(profileId, eventId) {
    return useQuery({
        queryKey: keys.detailCtx(profileId).eventDetails(eventId),
        queryFn: () => getAuditLogDetails(profileId, eventId),
        staleTime: DEFAULT_STALE_TIME,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load profile history details'))
    });
}
export function useUpdateProfileMutation() {
    return useMutation({
        mutationFn: (profile) => isNil(profile.id) ? postProfile(profile) : putProfile(profile.id, profile),
        onSuccess: async (data) => {
            const detailKey = keys.detail(data.id);
            queryClient.setQueryData(detailKey, data);
            await invalidateAllQueries(queryKey => {
                return !isEqual(queryKey, detailKey);
            });
        },
        meta: getMutationAlertMessages((error, profile) => {
            return formatApiError(error, isNil(profile.id) ? 'Failed to create a profile' : 'Failed to update a profile');
        }, (_data, profile) => {
            return isNil(profile.id) ? 'Profile has been created' : 'Profile has been updated';
        })
    });
}
export function useCreateProfileMutation() {
    return useMutation({
        mutationFn: (profile) => createProfile(profile),
        onSuccess: async () => {
            await queryClient.invalidateQueries(keys.all);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to create profile'), () => 'Profile has been created')
    });
}
export function useDeleteProfileMutation() {
    return useMutation({
        mutationFn: ({ profileId }) => deleteProfile(profileId),
        onSuccess: async (_data, { profileId }) => {
            const detailKey = keys.detail(profileId);
            await invalidateAllQueries(queryKey => {
                return !isEqual(detailKey, queryKey.slice(0, detailKey.length));
            });
            queryClient.removeQueries({ queryKey: detailKey });
        },
        meta: getMutationAlertMessages((error, { profileTitle }) => {
            return formatApiError(error, `Failed to delete profile '${profileTitle}'`);
        }, (_data, { profileTitle }) => `Profile '${profileTitle}' has been deleted`)
    });
}
export function useRestrictedPlugins() {
    return useQuery({
        queryKey: keys.currentUserProfile,
        queryFn: getCurrentUser,
        select: data => { var _a; return ((_a = data.features.ideProvisioningRestrictedPlugins) !== null && _a !== void 0 ? _a : []); }
    });
}
export function useProfileBackendChanges(profile, enabled = true) {
    return useQuery({
        queryKey: keys.backendChanges(profile.id, profile.version),
        queryFn: () => getProfile(profile.id),
        staleTime: DEFAULT_STALE_TIME,
        refetchOnWindowFocus: 'always',
        initialData: profile,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load profile')),
        select: (backendProfile) => {
            // TODO: investigate weather is it possible to have one version for profile (not the separate versions systems for each child setting
            return [
                (p) => p.version,
                (p) => p.plugins.version,
                (p) => p.settings.autoinstalledToolsVersion,
                (p) => p.settings.ideVersion,
                (p) => p.settings.marketplaceVersion,
                (p) => p.settings.toolboxVersion,
                (p) => p.settings.vmOptionsVersion,
                (p) => p.applications.version
            ].some(versionGetter => versionGetter(backendProfile) !== versionGetter(profile));
        },
        enabled: !!profile.id && enabled
    });
}
