// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trialEndMessage__ca3 {
  color: var(--ring-error-color);
}

.trialEndMessage__ca3 + .widgetContentWrapper__a79 {
  margin-top: calc(var(--ring-unit) * 2);
}

.trialStartMessage__eb8 {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/modules/constituents/widget/trial-notification-wrapper/trial-notification-wrapper.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,8BAA8B;AAChC","sourcesContent":[".trialEndMessage {\n  color: var(--ring-error-color);\n}\n\n.trialEndMessage + .widgetContentWrapper {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.trialStartMessage {\n  height: 100%;\n  display: flex;\n  flex-direction: column-reverse;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trialEndMessage": `trialEndMessage__ca3`,
	"widgetContentWrapper": `widgetContentWrapper__a79`,
	"trialStartMessage": `trialStartMessage__eb8`
};
export default ___CSS_LOADER_EXPORT___;
