import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import { useCompanyName } from '@app/common/hooks/use-company-name';
import { DownloadMachineConfigurationButton, DownloadTbeToolboxApp, useMachineConfigDownloadButtonProps } from '@app/modules/machine-config';
import { DocumentationLink } from '@common/documentation/documentation-link/documentaion-link';
import { CompanyNameEditor } from './company-name-editor';
import { ServerUrl } from './server-url/server-url';
import { LogoEditor } from './logo-editor/logo-editor';
import styles from './client-machine-configuration.css';
export function ClientMachineConfiguration(props) {
    const { className } = props;
    const [showDownloadToolbox, setShowDownloadToolbox] = useState(false);
    const { companyName, isLoading: companyNameIsLoading } = useCompanyName();
    const machineConfigProps = useMachineConfigDownloadButtonProps(`Join ${companyName}`);
    return (_jsxs("section", { className: className, children: [_jsx("div", { children: _jsxs("p", { children: ["Server URL: ", _jsx(ServerUrl, {})] }) }), _jsxs("div", { className: styles.companyName, children: ["Company name:", _jsx(CompanyNameEditor, { className: styles.companyNameEditor })] }), _jsx(LogoEditor, { className: styles.logo }), _jsxs("div", { className: styles.connectionBlock, children: [_jsx(H3, { children: "Connect to Server" }), _jsxs("div", { className: styles.infoText, children: ["Distribute the configuration file to developer machines within your organization to connect them to the server via the Toolbox App.", ' ', _jsx(DocumentationLink, { topic: "DISTRIBUTE_CONFIGURATION", children: "Learn more" }), "."] })] }), _jsxs("div", { className: styles.actions, children: [!companyNameIsLoading && (_jsx(Button, { onClickCapture: () => setShowDownloadToolbox(true), "data-test": "join-organization-button", ...machineConfigProps })), _jsx(DownloadMachineConfigurationButton, { children: "Download client machine configuration" })] }), showDownloadToolbox && (_jsxs("div", { className: styles.downloadToolboxContainer, children: [_jsxs(Text, { children: [_jsx("strong", { children: "Did that not work?" }), " ", _jsx("br", {}), " Ensure the Toolbox App is installed"] }), _jsx(DownloadTbeToolboxApp, {})] }))] }));
}
