import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import classNames from 'classnames';
import styles from './automation-token-cell.css';
export function AutomationTokenClientId(props) {
    const { token } = props;
    const isExpired = token.state === 'EXPIRED';
    const isDeleted = token.state === 'DELETED';
    return (_jsxs("span", { className: classNames({ [styles.expiredTokenClientId]: isExpired || isDeleted }), children: [token.clientId, ' ', isExpired && (_jsx(Badge, { disabled: true, className: classNames(styles.badge, styles.expiredBadge), children: "Expired" })), isDeleted && (_jsx(Badge, { disabled: true, className: classNames(styles.badge, styles.deletedBadge), children: "Deleted" }))] }));
}
